// @ts-nocheck
import { computed } from "vue";
import store from '@/store/index';
import { getConfig } from "./function";



const mstore:any = store;



// 获取系统
function getSystemType(){
    var muaAgent = navigator.userAgent.toLowerCase();
  
    if (/iphone|ipad|ipod/.test(muaAgent)) {
      return "ios";
    } else if (/android/.test(muaAgent)) {
      return "android";
    } else if (/windows/.test(muaAgent)) {
      return "windows";
    } else if (/mac/.test(muaAgent)) {
      return "mac";
    }
  
    return "Unknown";
}



// 公共属性
const PULIC_ATTR = () => {
    /*
        #app_version:版本号
        #account_id:当前用户ID
        #country:国家
        #country_code:国家代码
        load_type:H5写死
        pkg_name:H5写死
        device_system:系统
        appKey:appkey
        channel:渠道
    */

    ta.login(mstore?.state?.user?.userinfo?.userInfo?.uid);

    return {
        '#app_version': '0',
        '#account_id': mstore?.state?.user?.userinfo?.userInfo?.uid,
        '#country': getConfig().country,
        '#country_code': getConfig().country,
        'load_type': 'H5',
        'pkg_name': 'H5',
        'device_system': getSystemType(),
        'appKey': getConfig().appKey,
        'channel': sessionStorage.getItem('m_channel') || `${getSystemType()}_organic`,
    }
};



// 游戏加载启动
export const sr_game_launch = (info:any) => {
    /*
        gameid:游戏ID
        level:游戏场次
        progress:启动进度(启动游戏的时候报begin，成功进入游戏后再报complete)
        load_time:加载时长(单位秒)
        platformId:平台ID
    */

    let { gameid,level,progress,load_time,platformId } = info;

    ta.track("game_launch",{
        ...PULIC_ATTR(),
        'gameid': gameid,
        'level': level,
        'progress': progress,
        'load_time': load_time,
        'platformId': platformId
    });
}

// 注册送-弹窗展示
export const sr_register_gift_popup_show = (info:any) => {
    /* 
      show_type:展示类型
      popup_name:弹窗名称
      recharge_status:充值进度状态
    */

    let { recharge_status } = info;

    ta.track("show",{
        ...PULIC_ATTR(),
        'show_type': 'popup_show',
        'popup_name': 'newspecial',
        'recharge_status': recharge_status
    });
}

// 注册送-页面展示
export const sr_register_gift_page_show = (info:any) => {
    /* 
      show_type:展示类型
      page_name:弹窗名称
      recharge_status:充值进度状态
    */

    let { recharge_status } = info;

    ta.track("show",{
        ...PULIC_ATTR(),
        'show_type': 'page_show',
        'page_name': 'newspecial_detail',
        'recharge_status': recharge_status
    });
}

// 注册送-弹窗点击立即充值
export const sr_register_gift_popup_recharge = (info:any) => {
  /* 
    popup_name:弹窗名称
    click_element:点击元素
    recharge_status:充值进度状态
  */

  let { recharge_status } = info;

  ta.track("click",{
      ...PULIC_ATTR(),
      'popup_name': 'newspecial',
      'click_element': 'deposit_now',
      'recharge_status': recharge_status
  });
}

// 注册送-主界面入口点击
export const sr_register_gift_home_click = () => {
    /* 
      page_name:页面名称
      click_element:点击元素
    */

    ta.track("click",{
        ...PULIC_ATTR(),
        'page_name': 'home',
        'click_element': 'newspecial_entry',
    });
}

// 注册送-主界面领取金币点击
export const sr_register_gift_receive_click = () => {
  /* 
    page_name:页面名称
    click_element:点击元素
  */

  ta.track("click",{
      ...PULIC_ATTR(),
      'page_name': 'home',
      'click_element': 'newspecial_collect_coins',
  });
}

// 注册送-注册送主页面右上角规则点击
export const sr_register_gift_page_rule_click = () => {
  /* 
    page_name:页面名称
    click_element:点击元素
  */

  ta.track("click",{
      ...PULIC_ATTR(),
      'page_name': 'newspecial_detail',
      'click_element': 'activity_info',
  });
}

// 注册送-注册送主页面立即充值点击
export const sr_register_gift_page_recharge_click = (info:any) => {
  /* 
    page_name:页面名称
    click_element:点击元素
  */

  let { recharge_status } = info;

  ta.track("click",{
      ...PULIC_ATTR(),
      'page_name': 'newspecial_detail',
      'click_element': 'deposit_now',
      'recharge_status': recharge_status
  });
}

// 注册送-注册送主页面立即充值点击
export const sr_register_gift_page_receive_click = (info:any) => {
  /* 
    page_name:页面名称
    click_element:点击元素
  */

  let { recharge_status } = info;

  ta.track("click",{
      ...PULIC_ATTR(),
      'page_name': 'newspecial_detail',
      'click_element': 'collect_coins',
      'recharge_status': recharge_status
  });
}

// 首次启动上报整个URL
export const sr_report_all_url = () => {
  ta.track("launch_detail",{
      ...PULIC_ATTR(),
      "detail": location.href
  });
}